const AlbumCatalog = [
  {
    id: 0,
    title: "the weeknd - dawn fm",
    link: "/posters/0",
    images: "dawnfm",
  },
  {
    id: 1,
    title: "kanye west - graduation",
    link: "/posters/1",
    images: "graduation",
  },
  {
    id: 2,
    title: "taylor swift - midnights",
    link: "/posters/2",
    images: "midnights",
  },
  {
    id: 3,
    title: "xxxtentacion - 17",
    link: "/posters/3",
    images: "17",
  },
  {
    id: 4,
    title: "j cole - 2014 forest hills drive",
    link: "/posters/4",
    images: "2014 forest hills drive",
  },
  {
    id: 5,
    title: "the weeknd - beauty behind the madness",
    link: "/posters/5",
    images: "beauty behind the madness",
  },
  {
    id: 6,
    title: "post malone - beerbongs & bentleys",
    link: "/posters/6",
    images: "beerbongs _ bentleys",
  },
  {
    id: 7,
    title: "frank ocean - blonde",
    link: "/posters/7",
    images: "blonde",
  },
  {
    id: 8,
    title: "cas - cigarettes after sex",
    link: "/posters/8",
    images: "cigarettes after sex",
  },
  {
    id: 9,
    title: "drake - dark lane demo tapes",
    link: "/posters/9",
    images: "dark lane demo tapes",
  },
  {
    id: 10,
    title: "tory lanez - alone at prom",
    link: "/posters/10",
    images: "alone at prom",
  },
  {
    id: 11,
    title: "harry styles - harry's house",
    link: "/posters/11",
    images: "harrys house",
  },
  {
    id: 12,
    title: "the weeknd - house of balloons",
    link: "/posters/12",
    images: "house of balloons",
  },
  {
    id: 13,
    title: "21 savage - i am > i was",
    link: "/posters/13",
    images: "i am greater than i was",
  },
  {
    id: 14,
    title: "kendrick lamar - mr. morale & the big steppers",
    link: "/posters/14",
    images: "mr. morale _ the big steppers",
  },
  {
    id: 15,
    title: "drake - take care",
    link: "/posters/15",
    images: "take care",
  },
  {
    id: 16,
    title: "j cole - the off-season",
    link: "/posters/16",
    images: "the off-season",
  },
  {
    id: 17,
    title: "drake - views",
    link: "/posters/17",
    images: "views from the 6",
  },
  {
    id: 18,
    title: "the backseat lovers - waiting to spill",
    link: "/posters/18",
    images: "waiting to spill",
  },
  {
    id: 19,
    title: "brent faiyaz - wasteland",
    link: "/posters/19",
    images: "wasteland",
  },
  {
    id: 20,
    title: "sza - sos",
    link: "/posters/20",
    images: "sos",
  },
  {
    id: 21,
    title: "metro boomin - heroes & villains",
    link: "/posters/21",
    images: "heroes and villains",
  },
  {
    id: 22,
    title: "the strokes - comedown machine",
    link: "/posters/22",
    images: "comedown machine",
  },
  {
    id: 23,
    title: "taylor swift - speak now",
    link: "/posters/23",
    images: "speak now",
  },
  {
    id: 24,
    title: "mac miller - the divine feminine",
    link: "/posters/24",
    images: "the divine feminine",
  },
  {
    id: 25,
    title: "taylor swift - reputation",
    link: "/posters/25",
    images: "reputation",
  },
];

export default AlbumCatalog;
